'use client';

import { useLocalization } from '@akinon/next/hooks';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { resetHeaderState } from '@akinon/next/redux/reducers/header';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { getCookie } from '@akinon/next/utils';
import { GoogleTagManager } from '@next/third-parties/google';
import { ROUTES } from '@theme/routes';
import { pushPageView } from '@theme/utils/gtm';
import { createHideOnApp } from '@theme/utils/helper';
import { swanPageView } from '@theme/utils/swan';
import { useSession } from 'next-auth/react';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';
import OrganizationSchema from '@theme/models/organization';

export default function RootTemplate({
  children
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentUrl = process.env.NEXT_PUBLIC_URL + pathname + searchParams;
  const { locale, currency } = useLocalization();
  const { data: session, status } = useSession();

  useEffect(() => {
    dispatch(closeMiniBasket());
    dispatch(resetHeaderState());
  }, [dispatch, pathname, searchParams]);

  useEffect(() => {
    if (pathname === ROUTES.HOME) {
      window.scrollTo(0, 0);
    }
    pushPageView(pathname);
    swanPageView(pathname);
  }, [pathname]);

  const organizationLocale = OrganizationSchema[locale.slice(3)];

  const jsonLdWebSite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: currentUrl,
    name: organizationLocale?.name,
    alternateName: 'Lulu Hypermarket'
  };

  const jsonLdOrganization = {
    ...organizationLocale,
    url: currentUrl,
    logo: `${URL}/akn-logo.svg`,
  };

  useEffect(() => {
    const parsedPathname = pathname.split('/');
    if (
      parsedPathname.includes('checkout') ||
      parsedPathname.includes('basket')
    ) {
      const ymDivBar = document.getElementById('ymDivBar');
      ymDivBar?.classList.add('invisible');
    } else {
      const ymDivBar = document.getElementById('ymDivBar');
      ymDivBar?.classList.remove('invisible');
    }
  }, [pathname]);

  useEffect(() => {
    if (status !== 'loading') {
      window.ymConfig = {
        bot: 'x1648186599155',
        host: 'https://r1.cloud.yellow.ai',
        payload: {
          name: session?.user?.firstName,
          email: session?.user?.email,
          phoneNumber: session?.user?.phone
        }
      };

      const ymScript = document.createElement('script');
      ymScript.type = 'text/javascript';
      ymScript.async = true;
      ymScript.src =
        'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
      document.body.appendChild(ymScript);

      ymScript.onload = () => {
        const parsedPathname = window.location.pathname.split('/');
        const ymDivBar = document.getElementById('ymDivBar');

        if (
          parsedPathname.includes('checkout') ||
          parsedPathname.includes('basket')
        ) {
          ymDivBar?.classList.add('invisible');
        } else {
          ymDivBar?.classList.remove('invisible');
        }
      };
    }
  }, [session, status]);

  return (
    <>
      {/* Google Tag Manager */}
      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GTM_KEY || 'GTM-KTCHRL9'}
      />

      {/* End Google Tag Manager */}
      <meta
        name="google-site-verification"
        content="xap-z4HMc2KO5o5rOKEOankceetHEEsxIhMarxRXqmQ"
      />
      {/* Emarsys Web Extend Script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            var ScarabQueue = ScarabQueue || [];
            (function(id) {
              if (document.getElementById(id)) return;
              var js = document.createElement('script');
              js.id = id;
              js.src = '//cdn.scarabresearch.com/js/189390859720DA81/scarab-v2.js';
              var fs = document.getElementsByTagName('script')[0];
              fs.parentNode.insertBefore(js, fs);
            })('scarab-js-api');
          `
        }}
      />

      {/* Swan Ecom Events Tracking */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function (url) {
              _swan = window._swan || {};
              const d = document;
              const g = d.createElement('script');
              const s = d.getElementsByTagName('script')[0];
              g.type = 'text/javascript';
              g.async = true;
              g.src = 'https://swan-web-sdk.azureedge.net/trackingjs?country=${locale.split('-')[1].toUpperCase()}&currency=${currency.toUpperCase()}';
              s.parentNode.insertBefore(g, s);
              g.onload = function () {
                _swan.init({
                app_id: 'lulu_68363'
                });
              };
            })();
          `
        }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWebSite) }}
      />
      <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLdOrganization)
        }}
      />
      {createHideOnApp()}
      {children}
    </>
  );
}
