export const createHideOnApp = () => {
    if (typeof window !== 'undefined' && !document.getElementById('hide-on-app')) {
      const style = document.createElement('style');
      style.id = 'hide-on-app';
      style.type = 'text/css';

      if (isMobileApp()) {
        style.appendChild(
          document.createTextNode('.hide-on-app {display:none !important;}')
        );
      }

      document.head.appendChild(style);
    }
};

export const initHideOnApp = () => {
  const footer = document.getElementById('footer');

  if (isMobileApp()) {
    footer && (footer.style.marginTop = '128px');
  }
};

export const isMobileApp = () =>
  window.isMobileApp || localStorage.getItem('isMobileApp');
