import ae from './jsons/ae.organization.json';
import bh from './jsons/bh.organization.json';
import kw from './jsons/kw.organization.json';
import om from './jsons/om.organization.json';
import qa from './jsons/qa.organization.json';
import sa from './jsons/sa.organization.json';
export default {
  ae,
  bh,
  kw,
  om,
  qa,
  sa
};
